import { TasksCreate } from "src/pages/Admin/Project"
import { axiosClient as axios } from "../API"
import { API, createEndpoint } from "../API"
import { useUserContext } from "src/contexts/ApexUserContext"
import { ProjectCreate } from "src/interfaces/Project"
import { CodeserverTaskCreate } from "src/interfaces/Tasks"

let { endpoint } = API
export let GetUserProject = async (entraId: string) => {
    try {
        let res = await axios.get(createEndpoint(endpoint.getUserProject) + "/" + entraId)
        return res
    } catch (error) {
        return false
    }

}
export let GetProjectById = async (id: string) => {
    try {
        return await axios.get(createEndpoint(endpoint.getProjectById) + "/" + id)
    } catch (error) {
        return false
    }

}
export let GetProjectNamespaces = async (id: string) => {
    try {
        return await axios.get(createEndpoint(endpoint.getProjectNamespaces) + "/" + id)
    } catch (error) {
        return false
    }

}
export let RequestNamespace = async ({ owner_id, project_id, cluster_id }: { owner_id: string, project_id: string, cluster_id: string }) => {
    try {
        return await axios.post(createEndpoint(endpoint.requestNamespace), { owner_id, project_id, cluster_id })
    } catch (error) {
        return false
    }

}
export let CreateProject = async (data: ProjectCreate) => {
    // try {
    //     return (await axios.post(createEndpoint(endpoint.createProject), data))
    // } catch (error) {
    //     return false
    // }
    return (await axios.post(createEndpoint(endpoint.createProject), data))
}
export let DeleteProject = async (id: string) => {

    // try {
    return await axios.delete(createEndpoint(endpoint.deleteProject) + "/" + id)
    // } catch (error) {
    //     return false
    // }
}
export let CreateDefaultProject = async ({ owner_id, user_name, project_name }: { owner_id: string, user_name: string, project_name: string }) => {
    try {
        return await axios.post(createEndpoint(endpoint.createDefaultProject), { owner_id, user_name, name: project_name })
    } catch (error) {
        return false
    }

}
export let CreateTask = async (body: CodeserverTaskCreate) => {
    // try {
    //     return await axios.post(createEndpoint(endpoint.createTask), body)
    // } catch (error) {
    //     return false
    // }
    return await axios.post(createEndpoint(endpoint.createTask), body)

}
export let DeleteTask = async (params: { id: string }) => {
    // try {
    //     return await axios.delete(createEndpoint(endpoint.deleteTask) + "/" + params.id)
    // } catch (error) {
    //     return false
    // }
    return await axios.delete(createEndpoint(endpoint.deleteTask) + "/" + params.id)
}


export let CreateTasks = async (body: TasksCreate[]) => {
    try {
        return await axios.post(createEndpoint(endpoint.createTasks), body)
    } catch (error) {
        return false
    }

}
export let DeleteTasks = async (params: { id: string[] }) => {
    try {
        return await axios.delete(createEndpoint(endpoint.deleteTasks), { data: params })
    } catch (error) {
        return false
    }

}