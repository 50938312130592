import { useForm, SubmitHandler } from "react-hook-form";
import { ProjectCreate, ReqProjectResource } from "src/interfaces/Project";
import { UserContextType } from "src/types/userContextType";
import { useUserContext } from "src/contexts/ApexUserContext";
import { CreateProject } from "src/api/project";
import toast from "react-hot-toast";
type ProjectForms = {
  name: string;
  cpu: string;
  memory: string;
  gpu: string;
};

const ProjectModal = ({
  isOpen,
  setOpenModal,
  data,
  reload,
  setLoading,
}: {
  isOpen: boolean;
  setOpenModal: (v: boolean) => void;
  data: any;
  reload: () => void;
  setLoading: (v: boolean) => void;
}) => {
  let { user } = useUserContext() as UserContextType;
  const nameRegex = /^[a-z]([-a-z0-9]*[a-z0-9])?$/;

  const {
    register,
    handleSubmit,
    watch,
    setError,
    reset,
    formState: { errors },
  } = useForm<ProjectForms>({
    defaultValues: {
      name: "untitled",
      cpu: "16",
      memory: "32",
      gpu: "0",
    },
  });
  const onSubmit: SubmitHandler<ProjectForms> = (value) => {
    let { name, ...res } = value;
    if (data.map((item: { name: string }) => item.name).includes(name)) {
      setError("name", {
        type: "manual",
        message: "This Project name already exists",
      });
    } else if (!nameRegex.test(name)) {
      setError("name", {
        type: "manual",
        message:
          "Name must be lowercase alphanumeric characters or '-', start with a letter, and end with a letter or digit.",
      });
    } else {
      let reqRes: ProjectCreate = {
        owner_id: user?.ad_id as string,
        name: name,
        user_name: (user?.ipa_name as string) || (user?.apex_id as string),
        resource: { ...res },
      };
      toast.promise(CreateProject(reqRes), {
        loading: "Creating...",
        success: () => {
          setOpenModal(false);
          reload();
          return <b>Project successfully created.</b>;
        },
        error: <b>Project create failed. Please try again.</b>,
      });
      // CreateProject(reqRes).then((res) => {
      //   if (res) {
      //     setOpenModal(false);
      //     reload();
      //   } else {
      //     toast.error("Project created failed. Please try again.");
      //   }
      //   setLoading(false);
      // });
    }
  };
  if (isOpen) {
    return (
      <div className="cover absolute h-full  w-full bg-black  bg-opacity-80 mb-3 rounded flex justify-center items-center">
        <div
          className={`flex flex-col relative rounded px-12 py-8 max-w-[450px] w-[50%] min-h-[60%] bg-[#0e1219] gap-y-3`}
        >
          <h1 className="text-2xl font-[700]">New Project</h1>
          <div
            className="text-2xl text-white absolute right-[20px] top-[10px] cursor-pointer"
            onClick={() => {
              reset();
              setOpenModal(false);
            }}
          >
            x
          </div>
          <form
            action=""
            onSubmit={handleSubmit(onSubmit)}
            className="flex flex-col justify-between flex-1"
          >
            <div className="flex flex-col gap-y-6">
              <div className="flex flex-col gap-y-2">
                <label htmlFor="" className=" font-semibold">
                  Project name
                </label>
                <input
                  {...register("name", { required: true })}
                  className="bg-transparent border rounded-md p-1 px-2"
                />
                {errors.name && (
                  <p className="text-red-500 text-sm">{errors.name.message}</p>
                )}
              </div>
              <div className="flex gap-y-1 items-center flex-wrap   gap-x-[20px]">
                <label htmlFor="" className="text-sm font-semibold w-[70px]">
                  CPU
                </label>
                <div className="flex gap-x-2 items-center">
                  <input
                    type="number"
                    min={1}
                    {...register("cpu", { required: true })}
                    className="bg-transparent border rounded-md p-1 text-end w-20"
                  />
                  <span className=" text-[#888]">vCPU</span>
                </div>
              </div>
              <div className="flex gap-y-1 items-center flex-wrap gap-x-[20px]">
                <label htmlFor="" className="text-sm font-semibold  w-[70px]">
                  GPU
                </label>
                <div className="flex gap-x-2 items-center ">
                  <input
                    type="number"
                    min={0}
                    {...register("gpu", { required: true })}
                    className="bg-transparent border rounded-md p-1 text-end w-20"
                  />
                  <span className=" text-[#888]"></span>
                </div>
              </div>
              <div className="flex gap-y-1 items-center flex-wrap   gap-x-[20px]">
                <label htmlFor="" className="text-sm font-semibold   w-[70px]">
                  Mem
                </label>
                <div className="flex gap-x-2 items-center">
                  <input
                    type="number"
                    min={1}
                    {...register("memory", { required: true })}
                    className="bg-transparent border rounded-md p-1 text-end w-20"
                  />
                  <span className=" text-[#888]">Gi</span>
                </div>
              </div>
            </div>

            <button className="nav-btn " type="submit">
              Create
            </button>
          </form>
        </div>
      </div>
    );
  } else return <></>;
};

export default ProjectModal;
