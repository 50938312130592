import axios from "axios";
import React, { useState } from "react";
import { CreateTask } from "src/api/project";
import { useUserContext } from "src/contexts/ApexUserContext";
import { UserContextType } from "src/types/userContextType";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import {
  Box,
  FormControl,
  FormHelperText,
  Input,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Slider,
  TextField,
  Typography,
} from "@mui/material";
import { Resource } from "src/interfaces/Project";

type Inputs = {
  owner_id: string;
  project_id: string;
  task_type: string;
  task_name: string;
  task_pass: string;
  cpu_count: string;
  mem_count: string;
  gpu_count: string;
};

const CodeserverForm = ({
  setcreateTaskModal,
  loaddata,
  existName,
  resourceRemain,
}: {
  setcreateTaskModal: (v: boolean) => void;
  loaddata: () => void;
  existName: string[];
  resourceRemain: Resource;
}) => {
  let { user } = useUserContext() as UserContextType;
  let { id: projectId } = useParams();
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    control,
    formState: { errors },
  } = useForm<Inputs>({
    defaultValues: {
      owner_id: user?.ad_id as string,
      project_id: projectId as string,
      task_type: "codeserver",
      task_name: "untitled",
      cpu_count: resourceRemain.cpu,
      mem_count: resourceRemain.memory,
      gpu_count: resourceRemain.gpu,
    },
  });
  console.log(errors);
  
  let errMessage = {
    regex:
      "Name must be lowercase alphanumeric characters or '-', start with a letter, and end with a letter or digit.",
    nameExist: "Task name already exist.",
  };
  const nameRegex = /^[a-z]([-a-z0-9]*[a-z0-9])?$/;

  let onSubmit: SubmitHandler<Inputs> = async (data) => {
    console.log(data);
    setcreateTaskModal(false);
    toast.promise(CreateTask({ ...data }), {
      loading: "Task Creating...",
      success: () => {
        loaddata();
        return <b>Task successfully created.</b>;
      },
      error: <b>Task create failed. Please try again.</b>,
    });
  };

  return (
    <div className="flex flex-col justify-between flex-1">
      <h1 className="text-lg font-semibold ">Config resource</h1>
      <div className="flex flex-col gap-y-1 py-2 px-1">
        <Box
          width={"100%"}
          sx={{ display: "flex",flexFlow:"column", justifyContent: "center", gap: 1,marginBottom:2 }}
          component="form"
          onSubmit={handleSubmit(onSubmit)}
          id="task-form"
        >
          <Box sx={{ flex: "1" }}>
            <InputLabel className="!text-white">Task Name</InputLabel>

            <FormControl sx={{ width: "100%" }} variant="outlined">
              <OutlinedInput
                id="outlined-adornment-name"
                aria-describedby="outlined-name-helper-text"
                inputProps={{
                  "aria-label": "name",
                }}
                {...register("task_name", {
                  required: true,
                  minLength: 3,
                  maxLength: 50,
                  pattern: nameRegex,
                })}
                sx={{
                  height: "42px",
                  color: "white",
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "rgba(255, 255, 255, 0.8)",
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: "white",
                  },
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: "white",
                  },
                }}
              />
              {errors.task_name ? (
                <FormHelperText
                  id="outlined-weight-helper-text"
                  sx={{ color: "rgba(200, 0, 0, 0.8)" }}
                >
                  {errMessage.regex}
                </FormHelperText>
              ) : (
                <FormHelperText
                  id="outlined-weight-helper-text"
                  sx={{ color: "rgba(255, 255, 255, 0.8)" }}
                >
                  e.g. abc, abc123, or abc-123.
                </FormHelperText>
              )}
            </FormControl>
          </Box>
          <Box sx={{ flex: "1" }}>
            <InputLabel className="!text-white">Password</InputLabel>

            <FormControl sx={{ width: "100%" }} variant="outlined">
              <OutlinedInput
                id="outlined-adornment-pass"
                aria-describedby="outlined-pass-helper-text"
                inputProps={{
                  "aria-label": "pass",
                }}
                type="password"
                {...register("task_pass", {
                  required: true,
                  minLength: 8,
                  maxLength: 50,
                  
                })}
                sx={{
                  height: "42px",
                  color: "white",
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "rgba(255, 255, 255, 0.8)",
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: "white",
                  },
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: "white",
                  },
                }}
              />
              {errors.task_pass?.type === "minLength" && (
                <FormHelperText
                  id="outlined-weight-helper-text"
                  sx={{ color: "rgba(200, 0, 0, 0.8)" }}
                >
                  Password should be at least 8 characters long
                </FormHelperText>
              )}
              {errors.task_pass?.type === "maxLength" && (
                <FormHelperText
                  id="outlined-weight-helper-text"
                  sx={{ color: "rgba(200, 0, 0, 0.8)" }}
                >
                  Password should be at most 50 characters long
                </FormHelperText>
              )}
              {errors.task_pass?.type === "required" && (
                <FormHelperText
                  id="outlined-weight-helper-text"
                  sx={{ color: "rgba(200, 0, 0, 0.8)" }}
                >
                  Password is required
                </FormHelperText>
              )}
            </FormControl>
          </Box>
        </Box>
        <Box
          width={"100%"}
          sx={{ display: "flex", alignItems: "center", gap: 2 }}
        >
          <Box sx={{ flex: "1" }}>
            <InputLabel className="!text-white">Cpu</InputLabel>
            <Controller
              name="cpu_count"
              control={control}
              defaultValue={resourceRemain.cpu}
              disabled={resourceRemain.cpu === "0"}
              render={({ field }) => {
                const marks = [
                  {
                    value: 0,
                    label: "0",
                  },
                  {
                    value: parseInt(resourceRemain.cpu),
                    label: resourceRemain.cpu,
                  },
                ];
                return (
                  <Slider
                    sx={{
                      color: "white",
                      "& .MuiSlider-markLabel": {
                        color: "white",
                      },
                    }}
                    {...field}
                    value={parseInt(field.value) || 0}
                    onChange={(event, newValue) => {
                      field.onChange(newValue);
                      setValue("cpu_count", newValue.toString()); // update ค่าทันที
                    }}
                    min={0}
                    max={parseInt(resourceRemain.cpu)}
                    aria-labelledby="input-slider"
                    valueLabelDisplay="auto"
                    marks={marks}
                  />
                );
              }}
            />
          </Box>
          <Controller
            name="cpu_count"
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                sx={{
                  width: "70px",
                  color: "white",
                  "&:before": {
                    borderBottomColor: "rgba(255, 255, 255, 0.8)", // เส้นใต้ก่อนถูกคลิก
                  },
                  "&:after": {
                    borderBottomColor: "white", // เส้นใต้เมื่อโฟกัส
                  },
                  "&:hover:not(.Mui-disabled):before": {
                    borderBottomColor: "white", // เส้นใต้เมื่อ hover
                  },
                  "& .MuiTypography-root": {
                    color: "rgba(255, 255, 255, 0.8) !important",
                    paddingRight: "3px",
                  },
                  "& .MuiInputBase-input": {
                    textAlign: "right",
                    "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button":
                      {
                        display: "none",
                      },
                    "-moz-appearance": "textfield",
                  },
                }}
                value={field.value || ""}
                endAdornment={<InputAdornment position="end"></InputAdornment>}
                onChange={(event) => {
                  const newValue = Number(event.target.value);
                  if (!isNaN(newValue) && newValue >= 0 && newValue <= 100) {
                    field.onChange(newValue);
                    setValue("cpu_count", newValue.toString()); 
                  }
                }}
                inputProps={{
                  step: 1,
                  min: 1,
                  max: parseInt(resourceRemain.cpu),
                  type: "number",
                  "aria-labelledby": "input-slider",
                }}
              />
            )}
          />
        </Box>
        <Box
          width={"100%"}
          sx={{ display: "flex", alignItems: "center", gap: 2 }}
        >
          <Box sx={{ flex: "1" }}>
            <InputLabel className="!text-white">Gpu</InputLabel>
            <Controller
              name="gpu_count"
              control={control}
              // disabled={resourceRemain.gpu === "0"}
              defaultValue={resourceRemain.gpu}
              render={({ field }) => {
                const marks = [
                  { value: 0, label: "0" },
                  {
                    value: parseInt(resourceRemain.gpu),
                    label: resourceRemain.gpu,
                  },
                ];
                return (
                  <Slider
                    sx={{
                      color: "white",
                      "& .MuiSlider-markLabel": { color: "white" },
                    }}
                    {...field}
                    value={parseInt(field.value) || 0}
                    onChange={(event, newValue) => {
                      field.onChange(newValue);
                      setValue("gpu_count", newValue.toString());
                    }}
                    min={0}
                    max={parseInt(resourceRemain.gpu)}
                    aria-labelledby="input-slider"
                    valueLabelDisplay="auto"
                    marks={marks}
                  />
                );
              }}
            />
          </Box>
          <Controller
            name="gpu_count"
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                disabled={resourceRemain.gpu === "0"}
                sx={{
                  width: "70px",
                  color: "white",
                  "&:before": {
                    borderBottomColor: "rgba(255, 255, 255, 0.8)", // เส้นใต้ก่อนถูกคลิก
                  },
                  "&:after": {
                    borderBottomColor: "white", // เส้นใต้เมื่อโฟกัส
                  },
                  "&:hover:not(.Mui-disabled):before": {
                    borderBottomColor: "white", // เส้นใต้เมื่อ hover
                  },
                  "& .MuiTypography-root": {
                    color: "rgba(255, 255, 255, 0.8) !important",
                    paddingRight: "3px",
                  },
                  "& .MuiInputBase-input": {
                    textAlign: "right",
                    "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button":
                      {
                        display: "none",
                      },
                    "-moz-appearance": "textfield",
                  },
                }}
                endAdornment={<InputAdornment position="end"></InputAdornment>}
                value={field.value || ""}
                onChange={(event) => {
                  const newValue = Number(event.target.value);
                  if (!isNaN(newValue) && newValue >= 0 && newValue <= 100) {
                    field.onChange(newValue);
                    setValue("gpu_count", newValue.toString());
                  }
                }}
                inputProps={{
                  step: 1,
                  min: 0,
                  max: parseInt(resourceRemain.gpu),
                  type: "number",
                  "aria-labelledby": "input-slider",
                }}
              />
            )}
          />
        </Box>
        <Box
          width={"100%"}
          sx={{ display: "flex", alignItems: "center", gap: 2 }}
        >
          <Box sx={{ flex: "1" }}>
            <InputLabel className="!text-white">Memory</InputLabel>
            <Controller
              name="mem_count"
              control={control}
              disabled={resourceRemain.memory === "0"}
              defaultValue={resourceRemain.memory}
              render={({ field }) => {
                const marks = [
                  { value: 0, label: "0" },
                  {
                    value: parseInt(resourceRemain.memory),
                    label: resourceRemain.memory,
                  },
                ];
                return (
                  <Slider
                    sx={{
                      color: "white",
                      "& .MuiSlider-markLabel": { color: "white" },
                    }}
                    {...field}
                    value={parseInt(field.value) || 0}
                    onChange={(event, newValue) => {
                      field.onChange(newValue);
                      setValue("mem_count", newValue.toString());
                    }}
                    min={0}
                    max={parseInt(resourceRemain.memory)}
                    aria-labelledby="input-slider"
                    valueLabelDisplay="auto"
                    marks={marks}
                  />
                );
              }}
            />
          </Box>
          <Controller
            name="mem_count"
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                sx={{
                  width: "70px",
                  color: "white",
                  "&:before": {
                    borderBottomColor: "rgba(255, 255, 255, 0.8)", // เส้นใต้ก่อนถูกคลิก
                  },
                  "&:after": {
                    borderBottomColor: "white", // เส้นใต้เมื่อโฟกัส
                  },
                  "&:hover:not(.Mui-disabled):before": {
                    borderBottomColor: "white", // เส้นใต้เมื่อ hover
                  },
                  "& .MuiTypography-root": {
                    color: "rgba(255, 255, 255, 0.8) !important",
                    paddingRight: "3px",
                  },
                  "& .MuiInputBase-input": {
                    textAlign: "right",
                    "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button":
                      {
                        display: "none",
                      },
                    "-moz-appearance": "textfield",
                  },
                }}
                endAdornment={
                  <InputAdornment position="end">Gi</InputAdornment>
                }
                value={field.value || ""}
                onChange={(event) => {
                  const newValue = Number(event.target.value);
                  if (!isNaN(newValue) && newValue >= 0 && newValue <= 100) {
                    field.onChange(newValue);
                    setValue("mem_count", newValue.toString());
                  }
                }}
                inputProps={{
                  step: 1,
                  min: 0,
                  max: parseInt(resourceRemain.memory),
                  type: "number",
                  "aria-labelledby": "input-slider",
                }}
              />
            )}
          />
        </Box>
      </div>
      <div className="flex-grow w-full h-full flex flex-col-reverse">
        <div className="w-full disabled:opacity-40 flex flex-row-reverse ">
          <button className="nav-btn " type="submit" form="task-form">
            Create
          </button>
        </div>
      </div>
    </div>
  );
};

export default CodeserverForm;
