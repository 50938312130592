import { Gauge, gaugeClasses } from "@mui/x-charts/Gauge";
import { Resource } from "src/interfaces/Project";

export const ResourceGauge = ({
  resource,
  resourceRemain,
}: {
  resourceRemain: Resource;
  resource: Resource;
}) => {
  console.log(1,resourceRemain,resource);
  
  return (
    <div className="flex flex-col h-[22%] rounded-md  min-h-[200px] py-3 relative bg-[#21262b] border-[#3a3a3a] border-[1px]">
      {/* <div className="absolute h-full w-full bg-[radial-gradient(circle,_rgba(2,0,36,1)_0%,_rgba(0,212,255,1)_100%)]  opacity-20"></div> */}
      <h1 className="font-bold my-[5px] text-center text-2xl ">Remaining Resources</h1>
      <div className="flex flex-1 justify-evenly">
        <div className="flex flex-col gap-y-2 items-center w-[20%] rounded-md">
          <Gauge
          key={"adasdasd"}
            value={
              (parseInt(resourceRemain.cpu) / parseInt(resource.cpu)) * 100
            }
            startAngle={-110}
            endAngle={110}
            sx={{
              [`& .${gaugeClasses.valueText}`]: {
                fontSize: 15,
                color: "white",
                transform: "translate(0px, 0px)",
              },
              [`& .${gaugeClasses.valueText} text`]: {
                fill: "#FFFFFF",
                fontWeight: "500",
              },
            }}
            text={() => `${resourceRemain.cpu} / ${resource.cpu}`}
          />
          <p className="text-xl font-semibold">CPU</p>
        </div>
        <div className="flex flex-col gap-y-2 items-center w-[20%] rounded-md">
          <Gauge
          key={"adasdadsadsd"}

            value={
              (parseInt(resourceRemain.gpu) / parseInt(resource.gpu)) * 100
            }
            startAngle={-110}
            endAngle={110}
            sx={{
              [`& .${gaugeClasses.valueText}`]: {
                fontSize: 15,
                color: "white",
                transform: "translate(0px, 0px)",
              },
              [`& .${gaugeClasses.valueText} text`]: {
                fill: "#FFFFFF",
                fontWeight: "500",
              },
            }}
            text={() => `${resourceRemain.gpu} / ${resource.gpu}`}
          />
          <p className="text-xl font-semibold">GPU</p>
        </div>
        <div className="flex flex-col gap-y-2 items-center w-[20%] rounded-md">
          <Gauge
          key={"adasdadasdsdasd"}

            value={
              (parseInt(resourceRemain.memory) / parseInt(resource.memory)) *
              100
            }
            startAngle={-110}
            endAngle={110}
            sx={{
              [`& .${gaugeClasses.valueText}`]: {
                fontSize: 15,
                color: "white",
                transform: "translate(0px, 0px)",
              },
              [`& .${gaugeClasses.valueText} text`]: {
                fill: "#FFFFFF",
                fontWeight: "500",
              },
            }}
            text={() => `${resourceRemain.memory} / ${resource.memory}`}
          />
          <p className="text-xl font-semibold">Mem</p>
        </div>
      </div>
    </div>
  );
};
