import { useState } from "react";
import CodeserverForm from "./Tasks/Codeserver";
import { Resource } from "src/interfaces/Project";
const TaskModal = ({
  isOpen,
  loaddata,
  existName,
  setcreateTaskModal,
  resourceRemain,
}: {
  isOpen: boolean;
  loaddata: () => void;
  existName: string[];
  setcreateTaskModal: (v: boolean) => void;
  resourceRemain: Resource;
}) => {
  const [jobVal, setjobVal] = useState("codeserver");
  if (isOpen) {
    return (
      <div className="cover absolute  h-full w-full bg-black  bg-opacity-80 mb-3 rounded flex justify-center items-center">
        <div
          className={`flex flex-col relative rounded px-12 py-8 max-w-[450px] w-[50%] min-h-[60%] bg-[#0e1219] gap-y-3 `}
        >
          <div
            className="text-2xl text-white absolute right-[20px] top-[10px] cursor-pointer"
            onClick={() => {
              setcreateTaskModal(false);
            }}
          >
            x
          </div>
          <h1 className="text-2xl font-semibold">Select Your Task </h1>

          <div className="flex px- gap-x-2">
            <div className="cursor-pointer">
              <div
                id="codeserver"
                className={`h-[60px] w-[60px] p-2 rounded-md  border-2 hover:bg-white ${
                  jobVal === "codeserver" ? "bg-white" : ""
                } `}
                onClick={(e) => {
                  setjobVal("codeserver");
                  document.getElementById("codeserver")!.style.backgroundColor =
                    "white";
                }}
              >
                <img
                  className="h-full w-full"
                  src="https://artifacthub.io/image/82339e82-7d27-4579-980e-c101329b7aa8@1x"
                  alt=""
                />
              </div>
              <p className="text-center w-full text-[12px]">Code Server</p>
            </div>
          </div>
          <CodeserverForm
            resourceRemain={resourceRemain}
            existName={existName}
            loaddata={loaddata}
            setcreateTaskModal={setcreateTaskModal}
            key={1}
          />
        </div>
      </div>
    );
  } else return <></>;
};

export default TaskModal;
