import type { Page as PageType } from "../../types/page";
import Loading from "../../components/Loading";
import {
  getResourceUsageCurrentMouth,
  getResourceUsageThatMouth,
} from "../../api/dashboard";
import { useEffect, useState } from "react";
import { useUserContext } from "../../contexts/ApexUserContext";
import { UserContextType } from "../../types/userContextType";
import { DataUsage, UserUsageMonthQueryParams, UserUsageQueryParams } from "../../interfaces/UsageData";

import {
  Box,
  Container,
  Stack,
  Typography,
  Unstable_Grid2 as Grid,
} from "@mui/material";

import { OverviewCpuMinutes } from "../../components/Dashboard/old/overview-cpu-minutes";
import { OverviewDoneTasks } from "../../components/Dashboard/old/overview-done-tasks";
import { OverviewGpuMinutes } from "../../components/Dashboard/old/overview-gpu-minutes";
import { OverviewStorage } from "../../components/Dashboard/old/overview-storage";
import TaskList from "../../components/Dashboard/customs/SlurmTasksTable";
import UsageSelect from "src/components/Dashboard/UsageSelect";
import TaskListTab from "src/components/Dashboard/TaskListTab";

const Dashboard: PageType = () => {
  let { user } = useUserContext() as UserContextType;
  let [isLoading, setLoading] = useState<boolean>(true);
  let [isShowTaskDetail, setIsShowTaskDetail] = useState<boolean>(!false);
  let [data, setData] = useState<DataUsage>({
    CPUCost: 0,
    CPUTime: 0,
    GPUCost: 0,
    GPUTime: 0,
    Storage: 0,
    StorageCost: 0,
    Success: false,
    TotalTask: 0,
    TaskList: [],
    KubeTaskList: [],
  });
  const [selectedYear, setSelectedYear] = useState<number | undefined>();
  const [selectedMonth, setSelectedMonth] = useState<number | undefined>();
  const [inspectState, setInspectState] = useState(false);
  let showUsageCurrentMonth = (user: any) => {
    setSelectedMonth(undefined);
    setSelectedYear(undefined);
    let params:UserUsageQueryParams = {
      uid: user?.apex_id,
      ad_id:user?.ad_id,
    };
    getResourceUsageCurrentMouth(params).then(
      (res) => {
        if (res !== null) {          
          setData(res);
          setLoading(false);
        }
      }
    );
  };
  let showUsageThatMonth = (user: any) => {
    let params:UserUsageMonthQueryParams = {
      uid: user?.apex_id,
      ad_id:user?.ad_id,
      month: selectedMonth as number,
      year: selectedYear as number,
    };
    getResourceUsageThatMouth(params).then((res) => {
      if (res !== null) {
        setData(res);
        setLoading(false);
      }
    });
  };

  useEffect(() => {
    if (user) {
      showUsageCurrentMonth(user);
    }
  }, [user]);
  useEffect(() => {
    if (!inspectState) {
      showUsageCurrentMonth(user);
    }
  }, [inspectState]);
  useEffect(() => {
    if (inspectState && selectedYear && selectedMonth && user) {
      showUsageThatMonth(user);
    }
  }, [selectedMonth]);

  if (isLoading) {
    return <Loading isActive={isLoading} />;
  } else
    return (
      <div className="layout !relative">
        <div className="main-bg !absolute"></div>
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            py: 8,
            borderRadius: "25px",
            maxWidth: "100%",
          }}
          className="main-box"
        >
          <Container maxWidth={"xl"}>
            <Grid
              container
              disableEqualOverflow
              spacing={{
                xs: 3,
                lg: 4,
              }}
            >
              <Grid xs={12}>
                <Stack className="head-box">
                  <Typography
                    className="overviewText"
                    sx={{ paddingX: 2 }}
                    variant="h4"
                  >
                    Overview
                  </Typography>
                  <Stack direction="row" spacing={4}>
                    <UsageSelect
                      inspectState={inspectState}
                      setInspectState={setInspectState}
                      selectedYear={selectedYear}
                      setSelectedMonth={setSelectedMonth}
                      setSelectedYear={setSelectedYear}
                    />
                  </Stack>
                </Stack>
              </Grid>
              <Grid xs={12} md={12}>
                <OverviewDoneTasks amount={data.TotalTask} />
              </Grid>
              <Grid xs={12} md={4}>
                <OverviewCpuMinutes amount={data.CPUTime} cost={data.CPUCost} />
              </Grid>
              <Grid xs={12} md={4}>
                <OverviewGpuMinutes amount={data.GPUTime} cost={data.GPUCost} />
              </Grid>
              <Grid xs={12} md={4}>
                <OverviewStorage
                  amount={data.Storage}
                  cost={data.StorageCost}
                />
              </Grid>
              <Grid xs={12} md={12}>
                {isShowTaskDetail ? (
                  <div className="flex flex-col gap-y-2">
                    <div
                      className="bg-[#21262b] rounded-[20px] h-[50px] flex justify-center items-center gap-x-3 hover:bg-[#131820f7] cursor-pointer"
                      onClick={() => {
                        setIsShowTaskDetail(!isShowTaskDetail);
                      }}
                    >
                      <span className="text-xl font-semibold">
                        Hide Tasks Details
                      </span>
                      <img src="\assets\hide.png" alt="" width={25} />
                    </div>
                    <TaskListTab
                      data={data}
                      month={selectedMonth as number}
                      year={selectedYear as number}
                    />
                  </div>
                ) : (
                  <div
                    className="bg-[#21262b] rounded-[20px] h-[50px] flex justify-center items-center gap-x-3 hover:bg-[#131820f7] cursor-pointer"
                    onClick={() => {
                      setIsShowTaskDetail(!isShowTaskDetail);
                    }}
                  >
                    <span className="text-xl font-semibold">
                      See Tasks Details
                    </span>
                    <img src="\assets\view.png" alt="" width={25} />
                  </div>
                )}
              </Grid>
            </Grid>
          </Container>
        </Box>
      </div>
    );
};

export default Dashboard;
